import { Fragment, useEffect, useId, useRef, useState } from 'react'
import {
    ShoppingCartIcon, ChevronDownIcon, ChevronUpIcon
} from '@heroicons/react/24/outline'
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import { Button, Dialog, DialogTrigger, Heading, Modal, ModalOverlay } from 'react-aria-components';
import L from "leaflet";
import GpsAddressPicker from '../../../components/Inputs/GpsAddressPicker'
import FreightCalculator from '../../../components/Inputs/FreightCalculator'
import Cookies from 'universal-cookie';
import StoreApi from '../../../services/StoreApi'
import InputMask from 'react-input-mask';
import { ArrowPathIcon, CalendarIcon, ChevronLeftIcon } from '@heroicons/react/24/solid'
import ChatProApi from '../../../services/ChatProApi';
import { Bounce, Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const cookies = new Cookies();

interface Produto {
    id: number;
    nome: string;
    quantidade: number;
    preco: number;
}
interface CarrinhoProps {
    parametros: {
        carrinho: Produto[];
        parametros: any;
    };
    onCarrinhoChange: (novoCarrinho: any[]) => void; // Tipagem explícita da função
}

const paginate = 10; // Número de itens por página

export function Carrinho({ parametros, onCarrinhoChange }: CarrinhoProps) {

    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [pedidos, setPedidos] = useState([] as any);
    const [carrinhoAberto, setCarrinhoAberto] = useState(false);
    let [paymentOption, setPaymentOption] = useState<string | null>('dinheiro')
    const [address, setAddress] = useState<string>('');
    const [numero, setNumero] = useState<string | number>('');
    const [complemento, setComplemento] = useState<string>('');
    const [numeroDelivery, setNumeroDelivery] = useState<string | number>('');
    const [complementoDelivery, setComplementoDelivery] = useState<string>('');
    let [abaAberta, setAbaAberta] = useState<string | null>('')
    const [deliveryOption, setDeliveryOption] = useState<string>('pickup');
    const [produtos, setProdutos] = useState([] as any);
    const [expandedPedido, setExpandedPedido] = useState<number | null>(null);
    const [isAddressValid, setIsAddressValid] = useState(false);

    const togglePedido = (id: number) => {
        setExpandedPedido((prev) => (prev === id ? null : id));
    };

    const [radius, setRadius] = useState<number>(parametros.parametros.raioCobertura ?? 0);
    const [troco, setTroco] = useState(0);
    const [total, setTotal] = useState((produtos ?? produtos).reduce(
        (soma: number, produto: { quantidade: number; preco: number }) =>
            soma + produto.quantidade * produto.preco, 0));

    const [storeCoordinates, setStoreCoordinates] = useState<[number, number]>(parametros.parametros.coordenadas.split(',').map(Number));
    const [deliveryCoordinates, setDeliveryCoordinates] = useState<[number, number]>([-23.561684, -46.655981]);

    const [frete, setFrete] = useState<{ cost: number | null; message: string }>({
        cost: null,
        message: '',
    });


    const [phone, setPhone] = useState("");
    const [isValid, setIsValid] = useState(true);

    const handleChange = (event: any) => {
        let value = event.target.value;
        value = value.replace(/\D/g, ""); // Remove todos os caracteres que não sejam números
        setPhone(value);
        validatePhone(value);
    };

    const validatePhone = (value: any) => {
        const phoneDigits = value.replace(/\D/g, "");
        setIsValid(phoneDigits.length >= 10);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filterStatus, setFilterStatus] = useState<string>("");
    const [filterDate, setFilterDate] = useState<string>("");

    // Função para buscar pedidos
    const fetchPedidos = async (page = 1) => {
        const user = cookies.get("user") ? cookies.get("user") : null;

        // Definição dos filtros baseada na função do usuário
        let filters: Record<string, any> = {};

        if (user?.roles?.[0]?.name === "atendente") {
            filters = {
                codStoreEmpresa: { filterType: "EQUALS", filterValue: user?.data?.instances_cliente?.[0]?.id }
            };
        } else {
            filters = {
                codStoreEmpresa: { filterType: "EQUALS", filterValue: user?.data?.user_instance?.[0]?.id }
            };
        }

        // Adiciona filtros opcionais dinamicamente
        if (filterStatus) {
            filters["status"] = { filterType: "EQUALS", filterValue: filterStatus };
        }

        if (filterDate) {
            filters["created_at"] = { filterType: "DATE", filterValue: filterDate };
        }

        const orderBy = { id: "desc" };

        try {
            const response = await StoreApi.listStorePedido(paginate, filters, orderBy, page);
            setPedidos(response.data.data);
            setCurrentPage(response.data.current_page);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error("Erro ao buscar pedidos:", error);
        }
    };

    // Buscar pedidos ao montar o componente
    useEffect(() => {
        fetchPedidos();
    }, []);

    useEffect(() => {
        if (cookies.get("pedido") !== undefined) {
            setPhone(cookies.get("pedido").telefoneCliente)
        }

        const handler = setTimeout(() => {
            if (troco !== 0) {

                if (troco < total + frete.cost) {
                    setTroco(total + frete.cost);
                } else {
                    setTroco(troco);
                }

            }
        }, 500); // Aguarda 500ms após o usuário parar de digitar

        return () => clearTimeout(handler); // Cancela o timeout se digitar novamente
    }, [troco, total, frete]);

    const icon = new L.Icon({
        iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
    });

    const handleComplementoChange = (newComplemento: string) => {
        setComplemento(newComplemento);
        setComplementoDelivery(newComplemento);
    };

    useEffect(() => {
        setTotal(produtos.reduce(
            (soma: number, produto: { quantidade: number; preco: number }) =>
                soma + produto.quantidade * produto.preco,
            0
        ));
    }, [produtos]);

    useEffect(() => {
        console.log(parametros);
        setStoreCoordinates(parametros.parametros.coordenadas.split(',').map(Number));
    }, [parametros.parametros.endereco]);

    useEffect(() => {
        setProdutos(parametros.carrinho);
    }, [parametros.carrinho]);

    const handleClientCoordinatesChange = (coordinates: [number, number]) => {
        setDeliveryCoordinates(coordinates);
    };

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const aumentarQuantidade = (id: any) => {
        setProdutos((prev: any) => {
            const updatedProdutos = prev.map((produto: any) =>
                produto.id === id
                    ? { ...produto, quantidade: produto.quantidade + 1 }
                    : produto
            );
            setTotal(updatedProdutos.reduce(
                (soma: number, produto: { quantidade: number; preco: number }) =>
                    soma + produto.quantidade * produto.preco,
                0
            ));
            onCarrinhoChange(updatedProdutos);
            return updatedProdutos;
        });
    };

    const diminuirQuantidade = (id: any) => {
        setProdutos((prev: any) => {
            const updatedProdutos = prev
                .map((produto: any) =>
                    produto.id === id
                        ? { ...produto, quantidade: produto.quantidade - 1 }
                        : produto
                )
                .filter((produto: any) => produto.quantidade > 0);

            setTotal(
                updatedProdutos.reduce(
                    (soma: number, produto: { quantidade: number; preco: number }) =>
                        soma + produto.quantidade * produto.preco,
                    0
                )
            );
            onCarrinhoChange(updatedProdutos);
            return updatedProdutos;
        });
    };


    async function handlePedido(e: any) {

        // Dados para criar um novo produto
        try {

            // Criando o produto na loja
            StoreApi.createStorePedido(e).then((r: any) => {

                if (r.data === "Número do WhatsApp não encontrado!" || r.data === "Número do WhatsApp não encontrado.") {
                    toast.error("WhatsApp não encontrado!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                }
                else {

                    toast.success('Pedido Enviado.', {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });

                    cookies.set('pedido', r.data);
                    const paginate = 10;
                    const filters = {
                        codStoreEmpresa: { filterType: "EQUALS", filterValue: parametros.parametros.id },
                        telefoneCliente: { filterType: "EQUALS", filterValue: phone }
                    };
                    const orderBy = JSON.parse('{"id":"desc"}');

                    StoreApi.listStorePedido(paginate, filters, orderBy, currentPage).then((r) => {
                        console.log("Resposta da API:", r.data);
                        setPedidos(r.data.data);
                    });
                }
            });

        } catch (error) {
            console.error("Erro ao criar o pedido:", error);
        }


    }

    const removerProduto = (id: any) => {
        setProdutos((prev: any) => {
            const updatedProdutos = prev.filter((produto: any) => produto.id !== id);

            setTotal(
                updatedProdutos.reduce(
                    (soma: number, produto: { quantidade: number; preco: number }) =>
                        soma + produto.quantidade * produto.preco,
                    0
                )
            );
            onCarrinhoChange(updatedProdutos);
            return updatedProdutos;
        });
    };

    const formatCurrency = (val: number, sign: string) => {
        const c = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: sign,
        }).format(val);

        const z = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: sign,
        }).format(0);

        if (c !== z) {
            return c;
        }
        else {
            return z;
        }
    };

    const handleAddressChange = (newAddress: any) => {
        setAddress(newAddress);
    };

    const handleNumeroChange = (newNumero: string | number) => {
        setNumero(newNumero);
        setNumeroDelivery(newNumero);
    };

    const handleFreteChange = (newFrete: { cost: number | null; message: string }) => {
        setFrete(newFrete);

    };

    return (
        <>

            {console.log()}
            {parametros.parametros.endereco &&
                <div
                    className={`${isMobile
                        ? "fixed bottom-0 left-0 w-full z-20 bg-white shadow-lg"
                        : "fixed bottom-0 right-10 w-96 z-20 bg-white shadow-lg rounded-md"
                        }`}
                >
                    {/* Carrinho */}
                    <button
                        className="flex w-full items-center bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition"
                        onClick={() => setCarrinhoAberto(!carrinhoAberto)}
                    >
                        <ShoppingCartIcon
                            aria-hidden="true"
                            className="h-6 w-6 text-gray-400 hover:text-gray-500"
                        />
                        <div className="flex-1">
                            <span className="block text-sm font-semibold text-gray-800">
                                {cookies.get("pedido") !== undefined ? "Acompanhar Pedido" : "Carrinho"}
                            </span>
                            {cookies.get("pedido") !== undefined ? <></> : <span className="block text-xs text-gray-500">
                                {produtos !== undefined && produtos.length > 0 ? produtos.length : 0} itens
                            </span>}
                        </div>
                        {carrinhoAberto ? (
                            <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-auto" />
                        ) : (
                            <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-auto" />
                        )}
                    </button>

                    {/* Dropdown / Modal */}
                    {carrinhoAberto && (

                        <div
                            className={`fixed ${isMobile
                                ? "w-full bottom-0 left-0"
                                : "right-10 bottom-0 w-96"
                                } bg-white rounded-lg shadow-lg p-2`}
                            style={{ transform: isMobile ? "translateY(0)" : "translateY(0)" }}
                        >
                            <button
                                className={`flex w-full items-center bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition mb-4 `}
                                onClick={() => setCarrinhoAberto(!carrinhoAberto)}
                            >
                                <ShoppingCartIcon
                                    aria-hidden="true"
                                    className="h-6 w-6 text-gray-400 hover:text-gray-500"
                                />
                                <div className="flex-1">
                                    <span className="block text-sm font-semibold text-gray-800">
                                        {cookies.get("pedido") !== undefined ? "Acompanhar Pedido" : "Carrinho"}
                                    </span>
                                    {cookies.get("pedido") !== undefined ? <></> : <span className="block text-xs text-gray-500">
                                        {produtos !== undefined && produtos.length > 0 ? produtos.length : 0} itens
                                    </span>}
                                </div>
                                {carrinhoAberto ? (
                                    <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-auto" />
                                ) : (
                                    <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-auto" />
                                )}
                            </button>
                            {/* Abas estilo sanfona */}
                            {cookies.get("pedido") !== undefined ? <></>
                                :
                                <>
                                    <div className="accordion">
                                        {/* Aba Produtos no Carrinho */}
                                        <div className="accordion-item ">
                                            <div className="accordion-content">
                                                <div className="grid grid-cols-7 gap-4 px-4 py-2 text-sm font-bold text-gray-800 border-b">
                                                    <span className="col-span-2">Produto</span>
                                                    <span className="col-span-1">Quantidade</span>
                                                    <span className="col-span-1"></span>
                                                    <span className="col-span-1">Unidade</span>
                                                    <span className="col-span-1"></span>
                                                    <span className="col-span-1">Total</span>
                                                </div>
                                                <ul className="space-y-3 max-h-44 overflow-y-auto">
                                                    {produtos !== undefined && produtos.length > 0 ? (
                                                        <>
                                                            {produtos.map((produto: any) => (
                                                                <li
                                                                    key={produto.id}
                                                                    className="grid grid-cols-7 items-start border-b"
                                                                >
                                                                    <span className="col-span-2 text-sm font-medium text-gray-800 truncate">
                                                                        {produto.nome}
                                                                    </span>

                                                                    <div className="flex items-center space-x-2">
                                                                        <button
                                                                            className="px-1 py-0.5 text-sm bg-gray-200 rounded hover:bg-gray-300"
                                                                            onClick={() => {
                                                                                diminuirQuantidade(produto.id);
                                                                                if (produto.quantidade === 1) {
                                                                                    removerProduto(produto.id);
                                                                                }
                                                                            }}
                                                                            aria-label={`Diminuir quantidade de ${produto.nome}`}
                                                                        >
                                                                            -
                                                                        </button>
                                                                        <span className="text-sm">{produto.quantidade}</span>
                                                                        <button
                                                                            className="px-1 py-0.5 text-sm bg-gray-200 rounded hover:bg-gray-300"
                                                                            onClick={() => aumentarQuantidade(produto.id)}
                                                                            aria-label={`Aumentar quantidade de ${produto.nome}`}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                    <span className="text-sm font-medium text-gray-800">
                                                                    </span>
                                                                    <span className="text-sm font-medium text-gray-800">
                                                                        {formatCurrency(produto.preco, 'BRL')}
                                                                    </span>
                                                                    <span className="">
                                                                    </span>
                                                                    <span className="text-sm font-medium text-gray-800">
                                                                        {formatCurrency(produto.quantidade * produto.preco, 'BRL')}
                                                                    </span>
                                                                </li>
                                                            ))}
                                                            {/* Linha do subtotal */}
                                                            <li className="grid grid-cols-7 items-center font-bold text-gray-900 border-t">
                                                                <span className="col-span-5 text-right">Subtotal:</span>
                                                                <span className="col-span-2 text-right text-sm">
                                                                    {formatCurrency(
                                                                        produtos.reduce((acc: number, produto: any) => acc + produto.quantidade * produto.preco, 0),
                                                                        'BRL'
                                                                    )}
                                                                </span>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <span className="block text-xs text-gray-500">Nenhum produto adicionado.</span>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>

                                        {/* Aba Opções de Entrega */}
                                        <div className="accordion-item mt-1">
                                            <button
                                                className="accordion-header flex items-center justify-between w-full p-1 mr-10 text-center bg-gray-100 rounded-lg text-gray-800 font-semibold"
                                                onClick={() => setAbaAberta(abaAberta === 'entrega' ? null : 'entrega')}
                                            >
                                                Opções de Entrega
                                                {abaAberta === 'entrega' ? (
                                                    <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                            {abaAberta === 'entrega' && (
                                                <div className="accordion-content">
                                                    <div className="flex items-center space-x-6">
                                                        <label className="flex items-center justify-between cursor-pointer">
                                                            {deliveryOption === 'pickup' ?
                                                                <input
                                                                    type="radio"
                                                                    name="deliveryOption"
                                                                    value="pickup"
                                                                    className="mr-2 text-green-600 focus:ring-green-500"
                                                                    defaultChecked
                                                                    onChange={() => {
                                                                        setDeliveryOption("pickup");
                                                                    }}
                                                                />
                                                                : <input
                                                                    type="radio"
                                                                    name="deliveryOption"
                                                                    value="pickup"
                                                                    className="mr-2 text-green-600 focus:ring-green-500"
                                                                    onChange={() => {
                                                                        setDeliveryOption("pickup");
                                                                    }}
                                                                />}
                                                            <span className="text-gray-800">Retirar na Loja</span>
                                                        </label>
                                                        <label className="flex items-center justify-center cursor-pointer">
                                                            {deliveryOption === 'delivery' ?
                                                                <input
                                                                    type="radio"
                                                                    name="deliveryOption"
                                                                    value="delivery"
                                                                    className="mr-2 text-green-600 focus:ring-green-500"
                                                                    defaultChecked
                                                                    onChange={() => {
                                                                        setDeliveryOption("delivery");
                                                                    }}
                                                                />
                                                                :
                                                                <input
                                                                    type="radio"
                                                                    name="deliveryOption"
                                                                    value="delivery"
                                                                    className="mr-2 text-green-600 focus:ring-green-500"
                                                                    onChange={() => {
                                                                        setDeliveryOption("delivery");
                                                                    }}
                                                                />
                                                            }
                                                            <span className="text-gray-800">Receber em Casa</span>
                                                        </label>
                                                    </div>
                                                    {deliveryOption === "delivery" ? (
                                                        <div className="mt-3">
                                                            <GpsAddressPicker
                                                                onCodUserInstance={parametros.parametros.codUserInstance}
                                                                initialAddress={address}
                                                                initialNumero={numeroDelivery}
                                                                initialComplemento={complementoDelivery}
                                                                onAddressChange={handleAddressChange}
                                                                onNumeroChange={handleNumeroChange}
                                                                onComplementoChange={handleComplementoChange}
                                                                onCoordinatesChange={handleClientCoordinatesChange}
                                                                onValidationChange={setIsAddressValid}
                                                                radius={0}
                                                                isStore={false}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="w-full h-56 relative mt-3 mb-10">
                                                            <label className="flex items-center cursor-pointer mb-1">
                                                                {parametros.parametros.endereco} - {parametros.parametros.numero} - {parametros.parametros.complemento}
                                                            </label>
                                                            <div className="w-full h-64 mb-2" >
                                                                <MapContainer
                                                                    center={storeCoordinates}
                                                                    zoom={50}
                                                                    scrollWheelZoom={true}
                                                                    style={{ height: "100%", width: "100%", marginBottom: "10px" }}
                                                                >
                                                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                                                    <Marker position={storeCoordinates} icon={icon} />
                                                                </MapContainer>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                        <div
                                            className={`accordion-item ${deliveryOption !== 'delivery' && abaAberta === 'entrega' ? 'mt-10' : 'mt-1'
                                                }`}
                                            style={{
                                                marginTop:
                                                    deliveryOption !== 'delivery' && abaAberta === 'entrega' ? '90px' : '10px',
                                            }}
                                        >    <button
                                            className="accordion-header flex items-center justify-between w-full p-1 mr-10 text-center bg-gray-100 rounded-lg text-gray-800 font-semibold"
                                            onClick={() => setAbaAberta(abaAberta === 'pagamento' ? null : 'pagamento')}
                                        >
                                                Pagamento
                                                {abaAberta === 'pagamento' ? (
                                                    <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                                                ) : (
                                                    <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                                                )}
                                            </button>
                                            {abaAberta === 'pagamento' && (
                                                <div className="accordion-content p-2">
                                                    {/* Opções de Pagamento */}
                                                    <div className="flex flex-col space-y-1">
                                                        <p className="text-sm text-gray-600">
                                                            O pagamento será realizado na loja, no momento da retirada do pedido ou na entrega. <br />Selecione a o metodo de pagamento desejado:
                                                        </p>
                                                        <label className="flex items-center cursor-pointer">
                                                            <input
                                                                type="radio"
                                                                defaultChecked
                                                                name="paymentOption"
                                                                value="dinheiro"
                                                                className="mr-2 text-green-600 focus:ring-green-500"
                                                                onChange={() => setPaymentOption('dinheiro')}
                                                            />
                                                            <span className="text-gray-800">Dinheiro</span>
                                                        </label>
                                                        <label className="flex items-center cursor-pointer">
                                                            <input
                                                                type="radio"
                                                                name="paymentOption"
                                                                value="cartao"
                                                                className="mr-2 text-green-600 focus:ring-green-500"
                                                                onChange={() => setPaymentOption('cartao')}
                                                            />
                                                            <span className="text-gray-800">Cartão de Crédito/Débito</span>
                                                        </label>
                                                        <label className="flex items-center cursor-pointer">
                                                            <input
                                                                type="radio"
                                                                name="paymentOption"
                                                                value="pix"
                                                                className="mr-2 text-green-600 focus:ring-green-500"
                                                                onChange={() => setPaymentOption('pix')}
                                                            />
                                                            <span className="text-gray-800">PIX</span>
                                                        </label>

                                                    </div>
                                                    {paymentOption === 'dinheiro' && (
                                                        <div className="mt-1">
                                                            <label className="text-sm font-semibold text-gray-800">
                                                                Precisa de troco para quanto?
                                                            </label>
                                                            <input
                                                                type="number"
                                                                value={troco}
                                                                onChange={(e) => setTroco(Number(e.target.value))}
                                                                className="border rounded px-3 py-1 w-full"
                                                                min={total + frete.cost}
                                                            />
                                                        </div>
                                                    )}

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>}
                            {cookies.get("pedido") !== undefined ?
                                <>
                                    <DialogTrigger>
                                        <Button
                                            className="w-full mt-1 py-3 text-base font-medium text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                            onPress={() => {
                                                setIsOpen(true);
                                                const paginate = 10;
                                                const filters = {
                                                    codStoreEmpresa: { filterType: "EQUALS", filterValue: parametros.parametros.id },
                                                    telefoneCliente: { filterType: "EQUALS", filterValue: phone }
                                                };
                                                const orderBy = JSON.parse('{"id":"desc"}');

                                                StoreApi.listStorePedido(paginate, filters, orderBy, currentPage).then((r) => {
                                                    console.log("Resposta da API:", r.data);
                                                    setPedidos(r.data.data);
                                                });
                                            }}
                                        >
                                            Acompanhar Pedido
                                        </Button>

                                        {/* Modal Overlay */}
                                        <ModalOverlay isOpen={isOpen} onOpenChange={setIsOpen} className="my-overlay z-[9999]">
                                            <Modal className="my-modal">
                                                <Dialog className="relative bg-gray-100 border-gray-200 px-4 py-4 rounded-md h-full overflow-hidden flex flex-col">
                                                    {/* Botão de Voltar */}
                                                    <Button className="absolute top-2 left-2 px-2 py-1 rounded-lg bg-gray-100 hover:text-gray-600" slot="close">
                                                        <p className="flex text-sm text-gray-400">
                                                            <ChevronLeftIcon className="h-5 w-5 mt-1 text-gray-400" />
                                                            Voltar
                                                        </p>
                                                    </Button>

                                                    {/* Container com Scroll */}
                                                    <div className="overflow-y-auto max-h-[80vh] pr-2 mt-10">
                                                        <ArrowPathIcon className='w-6 h-6 cursor-pointer' onClick={() => {
                                                            const paginate = 10;
                                                            const filters = {
                                                                codStoreEmpresa: { filterType: "EQUALS", filterValue: parametros.parametros.id },
                                                                telefoneCliente: { filterType: "EQUALS", filterValue: phone }
                                                            };
                                                            const orderBy = JSON.parse('{"id":"desc"}');

                                                            StoreApi.listStorePedido(paginate, filters, orderBy, currentPage).then((r) => {
                                                                console.log("Resposta da API:", r.data);
                                                                setPedidos(r.data.data);
                                                            });
                                                        }} />
                                                        {pedidos && pedidos.length > 0 ? (
                                                            pedidos.map((p: any, i: number) => {
                                                                console.log(i);
                                                                if (i === 0) {
                                                                    if (p.status === "entregue" || p.status === "cancelado") {
                                                                        cookies.remove('pedido');
                                                                    }
                                                                }
                                                                return (
                                                                    <div key={p.id} className="w-full p-1 mt-2 border-b border-gray-300">
                                                                        <div
                                                                            className={`${p.status === "pendente" ? 'block' : 'flex'} justify-between items-center bg-gray-200 px-1 py-2 rounded-lg cursor-pointer`}
                                                                            onClick={() => togglePedido(p.id)}
                                                                        >
                                                                            <h2 className="flex whitespace-nowrap text-sm font-semibold">Pedido #{p.id}  <ChevronDownIcon
                                                                                className={`h-5 w-5 ml-3 transition-transform ${expandedPedido === p.id ? "rotate-0" : "rotate-180"
                                                                                    }`}
                                                                            />
                                                                                <span
                                                                                    className={`px-1 py-1 text-xs ml-2 whitespace-nowrap font-medium rounded-md ${p.status === "pendente"
                                                                                        ? "text-yellow-700 bg-yellow-50"
                                                                                        : p.status === "cancelado"
                                                                                            ? "text-red-700 bg-red-50"
                                                                                            : p.status === "entregue"
                                                                                                ? "text-green-700 bg-green-50"
                                                                                                : p.status === "em_preparacao"
                                                                                                    ? "text-yellow-700 bg-yellow-50"
                                                                                                    : p.status === "saiu_para_entrega"
                                                                                                        ? "text-yellow-700 bg-yellow-50"
                                                                                                        : "text-red-700 bg-red-50"
                                                                                        }`}
                                                                                >
                                                                                    {p.status === "pendente"
                                                                                        ? "Aguardando Confirmação"
                                                                                        : p.status === "entregue"
                                                                                            ? "Entregue"
                                                                                            : p.status === "em_preparacao"
                                                                                                ? "Em Preparação"
                                                                                                : p.status === "saiu_para_entrega"
                                                                                                    ? "Saiu para Entrega"
                                                                                                    : "Cancelado"}
                                                                                </span>
                                                                            </h2>
                                                                        </div>

                                                                        {/* Conteúdo do Pedido (Expandível/Recolhível) */}
                                                                        {expandedPedido === p.id && (
                                                                            <div className="mt-2">
                                                                                {/* Barra de Progresso do Pedido */}
                                                                                {p.status !== "cancelado" && p.status !== "entregue" && (
                                                                                    <div className="w-full mt-3">
                                                                                        <div className="relative pt-1">
                                                                                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-300">
                                                                                                <div
                                                                                                    style={{ width: `${p.status === "pendente" ? "25" : p.status === 'em_preparacao' ? "50" : p.status === 'saiu_para_entrega' ? '75' : p.status === 'entregue' ? '100' : '0'}%` }}
                                                                                                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
                                                                                                ></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                                {/* Produtos */}
                                                                                <div className="mt-2 grid grid-cols-2 gap-2">
                                                                                    {p.produtos.map((product: any) => (
                                                                                        <div
                                                                                            key={product.id}
                                                                                            className="bg-white shadow-sm rounded-lg p-2 flex flex-col items-center"
                                                                                        >
                                                                                            <img
                                                                                                alt={product.nome}
                                                                                                src={
                                                                                                    product.imagem
                                                                                                        ? `https://api.chatbr.xyz/uploads/produto/${product.imagem}`
                                                                                                        : ""
                                                                                                }
                                                                                                className="w-16 h-16 object-cover rounded-md"
                                                                                            />
                                                                                            <h3 className="text-xs font-medium text-gray-900 mt-2">{product.nome}</h3>
                                                                                            <p className="text-xs text-gray-500">
                                                                                                {product.quantidade} x {formatCurrency(product.preco, "BRL")}
                                                                                            </p>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>

                                                                                {/* Resumo do Pedido */}
                                                                                <div className="bg-gray-100 p-3 mt-1 rounded-lg">
                                                                                    <p className="text-xs text-gray-700">
                                                                                        <strong>Endereço:</strong> {p.enderecoCliente !== null ? p.enderecoCliente : p.enderecoLoja}
                                                                                    </p>
                                                                                    <p className="text-xs text-gray-700">
                                                                                        <strong>Pagamento:</strong>{" "}
                                                                                        <span className="font-semibold">
                                                                                            {p.metodoPagamento}
                                                                                        </span>
                                                                                    </p>
                                                                                    <p className="text-xs text-gray-700">
                                                                                        <strong>Total:</strong>{" "}
                                                                                        <span className="font-semibold">
                                                                                            {formatCurrency(p.valorTotal, "BRL")}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })
                                                        ) : (
                                                            <p className="text-center text-gray-500 text-sm mt-4">Nenhum pedido encontrado.</p>
                                                        )}
                                                    </div>
                                                </Dialog>
                                            </Modal>
                                        </ModalOverlay>
                                    </DialogTrigger>
                                </>
                                :
                                <>
                                    {deliveryOption === "delivery" ? (
                                        <>
                                            {/* Total e Finalização */}
                                            <div className="mt-1">
                                                <div className="block justify-center text-center items-center font-semibold text-gray-900 bg-gray-100 p-1 rounded-lg shadow-sm">
                                                    {address !== "" && <small>Local da Entrega: {address}, {numeroDelivery !== "" && 'Nº: ' + numeroDelivery}{complementoDelivery !== "" && complementoDelivery !== null && ", " + complementoDelivery}</small>}<br />

                                                    {paymentOption !== "" && frete.cost !== null && <small>Forma de Pagamento: {paymentOption} </small>}<br />

                                                    {storeCoordinates && deliveryCoordinates ?
                                                        <>
                                                            <FreightCalculator
                                                                storeCoordinates={storeCoordinates}
                                                                clientCoordinates={deliveryCoordinates}
                                                                radius={radius}
                                                                ratePerKm={parametros.parametros.valorRaio}
                                                                onFreteChange={handleFreteChange}
                                                            />
                                                        </> : <></>}
                                                    {paymentOption === "dinheiro" && formatCurrency((troco - total), 'BRL')[0] !== '-' && troco !== 0 &&
                                                        <small>{"Troco para " + formatCurrency(troco, 'BRL')}<br /></small>}
                                                    {paymentOption === "dinheiro" && frete.cost !== null && formatCurrency((troco - total + frete.cost), 'BRL')[0] !== '-' && frete.cost !== null && troco !== 0 && <small>{"Troco será de " + formatCurrency((troco - (total + frete.cost)), 'BRL')}<br /></small>}
                                                    <> <small>Valor total: {frete.cost !== null ? formatCurrency((total + frete.cost), 'BRL') : formatCurrency(total, 'BRL')}</small></>
                                                </div>
                                                {formatCurrency(total, 'BRL') !== new Intl.NumberFormat("pt-BR", {
                                                    style: "currency",
                                                    currency: 'BRL',
                                                }).format(0) && frete.cost !== null &&
                                                    <DialogTrigger>
                                                        <Button
                                                            className="w-full mt-1 py-3 text-base font-medium text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                            onPress={() => setIsOpen(true)}
                                                        >
                                                            Finalizar Pedido
                                                        </Button>
                                                        {/* Modal Overlay com z-index alto */}
                                                        <ModalOverlay isOpen={isOpen}
                                                            onOpenChange={setIsOpen}
                                                            className="my-overlay z-[9999]"
                                                        >
                                                            <Modal className="my-modal">

                                                                <Dialog className="relative bg-gray-100 border-gray-200 px-4 py-2 h-full overflow-hidden flex flex-col items-start">
                                                                    <Button className="w-full px-2 py-1 rounded-lg bg-gray-100 hover:text-g text-gray-100" slot="close"><p className='flex text-sm text-gray-400'><ChevronLeftIcon className="h-5 w-5 mt-1 text-gray-400" />Voltar</p></Button>


                                                                    {/* <p className="mt-2 text-sm text-gray-500">
                                                    </p> */}
                                                                    <div className="h-120 w-full overflow-auto px-4 py-4">
                                                                        {pedidos.length > 0 && (
                                                                            <>
                                                                                {pedidos.map((p: any, c: number) => (
                                                                                    <Fragment key={p.id}>
                                                                                        {c === 0 && pedidos.length > 0 && (
                                                                                            <h2 className="font-bold text-xl text-black mb-4 relative z-50">
                                                                                                Detalhes do pedido:
                                                                                            </h2>
                                                                                        )}
                                                                                        {c === 1 && pedidos.length > 0 && (
                                                                                            <h2 className="font-bold text-xl text-black mt-4 relative z-50">
                                                                                                Últimos pedidos:
                                                                                            </h2>
                                                                                        )}
                                                                                        <div className="mt-2 space-y-2 sm:mt-2 border-b pb-4">
                                                                                            <div className="md:flex md:items-baseline md:space-x-4">
                                                                                                <h2
                                                                                                    id={`${p.id}-heading`}
                                                                                                    className="text-sm font-medium text-gray-900 md:shrink-0"
                                                                                                >
                                                                                                    Pedido #{p.id}
                                                                                                    <p className="text-xs">
                                                                                                        {new Date(p.created_at).toLocaleString("pt-BR")}
                                                                                                    </p>
                                                                                                </h2>
                                                                                            </div>

                                                                                            <div className="space-y-2 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 md:min-w-0 md:flex-1">
                                                                                                <p className="text-sm font-medium text-gray-500">
                                                                                                    Status:{" "}
                                                                                                    <span className="text-xs m-2 bg-white text-yellow-500 uppercase">
                                                                                                        {p.status === "pendente" && "Aguardando confirmação"}
                                                                                                    </span>
                                                                                                </p>
                                                                                            </div>

                                                                                            <div className="w-full text-sm font-medium">
                                                                                                <p className="text-sm font-medium text-gray-500">
                                                                                                    Produtos:
                                                                                                </p>
                                                                                                {p.produtos.map((produto: any) => (
                                                                                                    <div
                                                                                                        key={produto.id}
                                                                                                        className="border-l pl-2 border-gray-200 sm:ml-6"
                                                                                                    >
                                                                                                        <p className="text-sm font-medium text-black">
                                                                                                            {produto.quantidade + "x" + " - " + produto.nome}
                                                                                                        </p>
                                                                                                        <p className="text-xsm font-small text-black">
                                                                                                            {formatCurrency(produto.preco * produto.quantidade, "BRL")}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                ))}
                                                                                                <p className="text-sm font-medium text-gray-500">
                                                                                                    Total: {p.valorTotal}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Fragment>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <hr className='w-full h-100 border border-gray-300 mt-10 mb-2' />
                                                                    <Heading slot="title" className="font-bold text-xl text-black mb-4 relative z-50">Confirme seu celular para Finalizar o pedido:</Heading>

                                                                    <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">
                                                                        Número de Telefone
                                                                    </label>
                                                                    <InputMask
                                                                        mask="(99) 99999-9999"
                                                                        value={phone}
                                                                        onChange={handleChange}
                                                                        className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${isValid ? "border-gray-300" : "border-red-500"}`}
                                                                        placeholder="(00) 00000-0000"
                                                                    />
                                                                    {!isValid && (
                                                                        <p className="text-red-500 text-sm mt-2">Número inválido</p>
                                                                    )}
                                                                    <button
                                                                        className="w-full mt-1 py-3 text-base font-medium text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                                        aria-label="Finalizar Pedido"
                                                                        onClick={() => {

                                                                            if (paymentOption !== "" && formatCurrency((troco - (total + frete.cost)), 'BRL')[0] !== '-') {
                                                                                var valorTroco = troco - (total + frete.cost);
                                                                            }
                                                                            else {
                                                                                var valorTroco = 0;
                                                                            }

                                                                            var enderecoLoja = parametros.parametros.endereco + ", " + parametros.parametros.numero;
                                                                            if (parametros.parametros.complemento !== null) {
                                                                                enderecoLoja = enderecoLoja + ", " + parametros.parametros.complemento;
                                                                            }

                                                                            const pedido =
                                                                            {
                                                                                'codStoreEmpresa': parametros.parametros.id,
                                                                                'coordenadasCliente': deliveryCoordinates.join(', '),
                                                                                'enderecoCliente': address + ", " + numeroDelivery + ", " + complementoDelivery,
                                                                                'coordenadasLoja': parametros.parametros.coordenadas,
                                                                                'enderecoLoja': enderecoLoja,
                                                                                'telefoneCliente': phone,
                                                                                'produtos': produtos,
                                                                                'valorFrete': frete.cost,
                                                                                'valorProdutos': total,
                                                                                'valorTotal': total + frete.cost,
                                                                                'troco': valorTroco,
                                                                                'metodoPagamento': paymentOption

                                                                            };
                                                                            console.log(pedido);

                                                                            if (isAddressValid === true) {
                                                                                handlePedido(pedido)
                                                                            }

                                                                        }
                                                                        }
                                                                    >
                                                                        Finalizar Pedido
                                                                    </button>
                                                                    <ToastContainer
                                                                        position="top-right"
                                                                        autoClose={5000}
                                                                        hideProgressBar={false}
                                                                        newestOnTop={true}
                                                                        closeOnClick={false}
                                                                        rtl={false}
                                                                        pauseOnFocusLoss
                                                                        draggable
                                                                        pauseOnHover
                                                                        theme="light"
                                                                        transition={Slide}
                                                                        style={{ zIndex: 999999, position: "relative" }} // Z-index muito alto
                                                                    />
                                                                </Dialog>
                                                            </Modal>
                                                        </ModalOverlay>
                                                    </DialogTrigger>
                                                    /*   */
                                                }
                                            </div>
                                        </>
                                    ) : (<>

                                        <div className="mt-3">
                                            <div className="block justify-center text-center items-center font-semibold text-gray-900 bg-gray-100 p-1 rounded-lg shadow-sm">

                                                {parametros.parametros.endereco !== "" && <small>Local da Retirada: {parametros.parametros.endereco}, {parametros.parametros.numero !== "" && 'Nº: ' + parametros.parametros.numero}{parametros.parametros.complemento !== "" && parametros.parametros.complemento !== null && ", " + parametros.parametros.complemento}</small>}
                                                <br />
                                                {paymentOption !== "" && <small>Forma de Pagamento: {paymentOption} </small>}<br />
                                                {paymentOption === "dinheiro" && formatCurrency((troco - total), 'BRL')[0] !== '-' &&
                                                    troco !== null && troco !== 0 && <small>{"Troco para " + formatCurrency(troco, 'BRL')}<br /></small>}
                                                {paymentOption === "dinheiro" && formatCurrency((troco - total), 'BRL')[0] !== '-' &&
                                                    troco !== null && troco > total && troco !== 0 && <small> {"Troco será de " + formatCurrency((troco - total), 'BRL')}<br /></small>}

                                                <small>Valor total: {total !== 0 ? formatCurrency(total, 'BRL') : formatCurrency(0, 'BRL')}<br /></small>
                                            </div>
                                            {formatCurrency(total, 'BRL') !== new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: 'BRL',
                                            }).format(0) &&
                                                <DialogTrigger>
                                                    <Button
                                                        className="w-full mt-1 py-3 text-base font-medium text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                        onPress={() => setIsOpen(true)}
                                                    >
                                                        Finalizar Pedido
                                                    </Button>
                                                    {/* Modal Overlay com z-index alto */}
                                                    <ModalOverlay isOpen={isOpen}
                                                        onOpenChange={setIsOpen}
                                                        className="my-overlay z-[9999]"
                                                    >
                                                        <Modal className="my-modal">

                                                            <Dialog className="relative bg-gray-100 border-gray-200 px-4 py-2 h-full overflow-hidden flex flex-col items-start">
                                                                <Button className="w-full px-2 py-1 rounded-lg bg-gray-100 hover:text-g text-gray-100" slot="close"><p className='flex text-sm text-gray-400'><ChevronLeftIcon className="h-5 w-5 mt-1 text-gray-400" />Voltar</p></Button>


                                                                {/* <p className="mt-2 text-sm text-gray-500">
                                        </p> */}
                                                                <div className="h-120 w-full overflow-auto">
                                                                    <div className="p-4 bg-gray-100 min-h-screen">
                                                                        {/* Filtros */}
                                                                        {pedidos.length > 0 &&
                                                                            pedidos.map((p: any, c: number) => (
                                                                                <div key={p.id} className="mt-4 space-y-4 bg-white p-4 shadow rounded-md">
                                                                                    {/* Cabeçalhos Dinâmicos */}
                                                                                    {c === 0 && <h2 className="font-bold text-xl text-black mb-4">Detalhes do pedido:</h2>}
                                                                                    {c === 1 && <h2 className="font-bold text-xl text-black mt-4">Últimos pedidos:</h2>}

                                                                                    {/* Informações do Pedido */}
                                                                                    <div className="mt-2 space-y-2">
                                                                                        <div className="md:flex md:items-baseline md:space-x-4">
                                                                                            <h2 id={`${p.id}-heading`} className="text-sm font-medium text-gray-900 md:shrink-0">
                                                                                                Pedido #{p.id}
                                                                                                <p className="text-xs">{new Date(p.created_at).toLocaleString("pt-BR")}</p>
                                                                                            </h2>
                                                                                        </div>

                                                                                        {/* Status do Pedido */}
                                                                                        <div className="space-y-0 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 md:min-w-0 md:flex-1">
                                                                                            <p className="text-sm font-medium text-gray-500">Status: </p>
                                                                                            <p className="text-xs m-2 bg-white text-yellow-500 uppercase">
                                                                                                {p.status === "pendente" && "Aguardando confirmação"}
                                                                                            </p>
                                                                                        </div>

                                                                                        {/* Produtos */}
                                                                                        <div className="w-full text-sm font-medium">
                                                                                            <p className="text-sm font-medium text-gray-500">Produtos:</p>
                                                                                            {p.produtos.map((produto: any, index: number) => (
                                                                                                <div key={index} className="border-l border-gray-200 sm:ml-6 pl-2">
                                                                                                    <p className="text-sm font-medium text-black">
                                                                                                        {produto.quantidade}x - {produto.nome}
                                                                                                    </p>
                                                                                                    <p className="text-xs font-small text-black">
                                                                                                        {formatCurrency(produto.preco * produto.quantidade, "BRL")}
                                                                                                    </p>
                                                                                                </div>
                                                                                            ))}
                                                                                            <p className="text-sm font-medium text-gray-500">Total: {formatCurrency(p.valorTotal, "BRL")}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}

                                                                        {/* Paginação */}
                                                                        {totalPages > 1 && (
                                                                            <div className="flex justify-center mt-4">
                                                                                {Array.from({ length: totalPages }, (_, i) => (
                                                                                    <button
                                                                                        key={i}
                                                                                        onClick={() => fetchPedidos(i + 1)}
                                                                                        className={`px-3 py-1 mx-1 border rounded-md ${currentPage === i + 1 ? "bg-indigo-600 text-white" : "bg-white text-gray-700"
                                                                                            }`}
                                                                                    >
                                                                                        {i + 1}
                                                                                    </button>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                                <hr className='w-full h-100 border border-gray-300 mt-10 mb-2' />
                                                                <Heading slot="title" className="font-bold text-xl text-black mb-4 relative z-50">Confirme seu celular para Finalizar o pedido:</Heading>

                                                                <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">
                                                                    Número de Telefone
                                                                </label>
                                                                <InputMask
                                                                    mask="(99) 99999-9999"
                                                                    value={phone}
                                                                    onChange={handleChange}
                                                                    className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${isValid ? "border-gray-300" : "border-red-500"
                                                                        }`}
                                                                    placeholder="(00) 00000-0000"
                                                                />
                                                                {!isValid && (
                                                                    <p className="text-red-500 text-sm mt-2">Número inválido</p>
                                                                )}
                                                                {!isAddressValid && deliveryOption === "delivery" && (
                                                                    <p className="text-red-500 text-sm mt-2">Selecione um endereço do mapa.</p>
                                                                )}
                                                                <button
                                                                    className="w-full mt-1 py-3 text-base font-medium text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                                    aria-label="Finalizar Pedido"
                                                                    onClick={() => {

                                                                        if (paymentOption !== "" && formatCurrency((troco - total), 'BRL')[0] !== '-') {
                                                                            var valorTroco = troco - total;
                                                                        }
                                                                        else {
                                                                            var valorTroco = 0;
                                                                        }

                                                                        var enderecoLoja = parametros.parametros.endereco + ", " + parametros.parametros.numero;
                                                                        if (parametros.parametros.complemento !== null) {
                                                                            enderecoLoja = enderecoLoja + ", " + parametros.parametros.complemento;
                                                                        }
                                                                        const pedido =
                                                                        {
                                                                            'codStoreEmpresa': parametros.parametros.id,
                                                                            'coordenadasLoja': parametros.parametros.coordenadas,
                                                                            'enderecoLoja': enderecoLoja,
                                                                            'telefoneCliente': phone,
                                                                            'produtos': produtos,
                                                                            'valorFrete': 0,
                                                                            'valorProdutos': total,
                                                                            'valorTotal': total,
                                                                            'troco': valorTroco,
                                                                            'metodoPagamento': paymentOption

                                                                        };

                                                                        handlePedido(pedido)

                                                                    }
                                                                    } >
                                                                    Finalizar Pedido
                                                                </button>
                                                                <ToastContainer
                                                                    position="top-right"
                                                                    autoClose={5000}
                                                                    hideProgressBar={false}
                                                                    newestOnTop={true}
                                                                    closeOnClick={false}
                                                                    rtl={false}
                                                                    pauseOnFocusLoss
                                                                    draggable
                                                                    pauseOnHover
                                                                    theme="light"
                                                                    transition={Slide}
                                                                    style={{ zIndex: 999999, position: "relative" }} // Z-index muito alto
                                                                />
                                                            </Dialog>
                                                        </Modal>
                                                    </ModalOverlay>
                                                </DialogTrigger>

                                            }
                                        </div>
                                    </>)}</>}
                        </div>
                    )
                    }
                </div >}



        </>




    )

}
