import api from "./Api";

const createStoreEmpresa = async (data: any) => {
  return await api.post("/store/empresa", data).then((response) => {
    return response;
  });
};

const listStoreEmpresas = async (params = {}) => {
  return await api.get("/store/empresa", {
    params: params,
  }).then((response) => {
    return response;
  });
};

const getStoreEmpresa = async (id: any) => {
  return await api.get(`/store/empresa/${id}`).then((response) => {
    return response;
  });
};

const updateStoreEmpresa = async (id: any, file: File | null, data: any) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);


    for (const key in data) {
      if (data[key] !== undefined && data[key] !== null) {
        formData.append(key, data[key].toString());
      } else if (data[key] === null) {
        formData.append(key, ''); // Ou outro valor padrão
      }
    }

    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    formData.append('_method', 'PUT');
    try {
      await api.post(`/store/empresa/${id}`, formData).then((response) => {
        return response;
      });
    } catch (error) {
      console.error('Erro ao atualizar empresa:', error);
      throw error;
    }
  }
  else {
    try {
      const response = await api.put(`/store/empresa/${id}`, data);
      return response;
    } catch (error) {
      console.error('Erro ao atualizar empresa:', error);
      throw error;
    }
  }
};

const deleteStoreEmpresa = async (id: any) => {
  return await api.delete(`/store/empresa/${id}`).then((response) => {
    return response;
  });
};

const createStoreCategoria = async (data: any) => {
  return await api.post("/store/categoria", data).then((response) => {
    return response;
  });
};

const listStoreCategoria = async (params = {}) => {
  return await api.get("/store/categoria", {
    params: params,
  }).then((response) => {
    return response;
  });
};

const getStoreCategoria = async (id: any) => {
  return await api.get(`/store/categoria/${id}`).then((response) => {
    return response;
  });
};

const updateStoreCategoria = async (id: any, data: any) => {

  try {
    const response = await api.put(`/store/categoria/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar categoria:', error);
    throw error;
  }
};

const deleteStoreCategoria = async (id: any) => {
  return await api.delete(`/store/categoria/${id}`).then((response) => {
    return response;
  });
};

const createStoreProduto = async (data: any, file: File | null) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);

    for (const key in data) {
      if (data[key] !== undefined && data[key] !== null) {
        formData.append(key, data[key].toString());
      } else if (data[key] === null) {
        formData.append(key, ''); // Ou outro valor padrão
      }
    }

    try {
      const response = await api.post(`/store/produto`, formData);
      return response;
    } catch (error) {
      console.error('Erro ao atualizar produto:', error);
      throw error;
    }
  }
  else
  {
    try {
      const response = await api.post(`/store/produto`, data);
      return response;
    } catch (error) {
      console.error('Erro ao atualizar produto:', error);
      throw error;
    }
  }

 
};

const listStoreProduto = async (params = {}) => {
  return await api.get("/store/produto", {
    params: params,
  }).then((response) => {
    return response;
  });
};

const getStoreProduto = async (id: any) => {
  return await api.get(`/store/produto/${id}`).then((response) => {
    return response;
  });
};

const updateStoreProduto = async (id: any, file: File | null, data: any) => {

  if (file) {
    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }

    for (const key in data) {
      if (data[key] !== undefined && data[key] !== null) {
        formData.append(key, data[key].toString());
      } else if (data[key] === null) {
        formData.append(key, ''); // Ou outro valor padrão
      }
    }

    formData.append('_method', 'PUT');
    try {
      const response = await api.post(`/store/produto/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar produto:', error);
      throw error;
    }
  }
  else {
    try {
      const response = await api.put(`/store/produto/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar produto:', error);
      throw error;
    }
  }


};

const deleteStoreProduto = async (id: any) => {
  return await api.delete(`/store/produto/${id}`).then((response) => {
    return response;
  });
};

const createStorePedido = async (data: any) => {
    try {
      const response = await api.post(`/store/pedido`, data);
      return response;
    } catch (error) {
      console.error('Erro ao criar pedido:', error);
      throw error;
    }
 
};

const listStorePedido = async (paginate: any, filters : any, orderBy : any, page : any ) => {
  return await api.get(`/store/pedido?page=${page}`, {
    params: {paginate, filters, orderBy}, 
  }).then((response) => {
    return response;
  });
};

const getStorePedido = async (id: any) => {
  return await api.get(`/store/pedido/${id}`).then((response) => {
    return response;
  });
};

const updateStorePedido = async (id: any, data: any) => {
    try {
      const response = await api.put(`/store/pedido/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar pedido:', error);
      throw error;
    }

};

const deleteStorePedido = async (id: any) => {
  return await api.delete(`/store/pedido/${id}`).then((response) => {
    return response;
  });
};

export default {
  createStoreEmpresa,
  listStoreEmpresas,
  getStoreEmpresa,
  updateStoreEmpresa,
  deleteStoreEmpresa,
  createStoreCategoria,
  listStoreCategoria,
  getStoreCategoria,
  updateStoreCategoria,
  deleteStoreCategoria,
  createStoreProduto,
  listStoreProduto,
  getStoreProduto,
  updateStoreProduto,
  deleteStoreProduto,
  createStorePedido,
  listStorePedido,
  getStorePedido,
  updateStorePedido,
  deleteStorePedido
};
